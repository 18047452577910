import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from "docz";
import designTokens from "@urbaninfrastructure/design-tokens";
import * as designTokenPreviews from "./components/designTokens";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <p>{`A set of colors used across our designs to ensure consistency.`}</p>
    <h2 {...{
      "id": "theme-colors"
    }}>{`Theme colors`}</h2>
    <p>{`The dark version is used for hover colors. Accessible from `}<inlineCode parentName="p">{`designTokens.theme.{color}`}</inlineCode>{`. In `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{` each active theme's primary color can be accessed from `}<inlineCode parentName="p">{`theme.colors.primary`}</inlineCode>{`.`}</p>
    <designTokenPreviews.ThemeColors />
    <h2 {...{
      "id": "palette"
    }}>{`Palette`}</h2>
    <p>{`These colors are accessible as `}<inlineCode parentName="p">{`designTokens.palette.{color}`}</inlineCode>{`, or as `}<inlineCode parentName="p">{`theme.colors.palette.{color}`}</inlineCode>{` in `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{`.`}</p>
    <designTokenPreviews.PaletteColors />
    <h2 {...{
      "id": "light-palette"
    }}>{`Light palette`}</h2>
    <p>{`These colors are accessible as `}<inlineCode parentName="p">{`designTokens.paletteLight.{color}`}</inlineCode>{`, or as `}<inlineCode parentName="p">{`theme.colors.paletteLight.{color}`}</inlineCode>{` in `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{`.`}</p>
    <designTokenPreviews.LightPaletteColors />
    <h2 {...{
      "id": "dark-palette"
    }}>{`Dark palette`}</h2>
    <p>{`These colors are accessible as `}<inlineCode parentName="p">{`designTokens.paletteDark.{color}`}</inlineCode>{`, or as `}<inlineCode parentName="p">{`theme.colors.paletteDark.{color}`}</inlineCode>{` in `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{`.`}</p>
    <designTokenPreviews.DarkPaletteColors />
    <h2 {...{
      "id": "neutral"
    }}>{`Neutral`}</h2>
    <p>{`These colors are accessible as `}<inlineCode parentName="p">{`designTokens.neutral.{color}`}</inlineCode>{`, or as `}<inlineCode parentName="p">{`theme.colors.neutral[{key}]`}</inlineCode>{` in `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{`.`}</p>
    <designTokenPreviews.NeutralColors />
    <h2 {...{
      "id": "accent"
    }}>{`Accent`}</h2>
    <p>{`These colors are accessible as `}<inlineCode parentName="p">{`designTokens.accent.{color}`}</inlineCode>{`, or as `}<inlineCode parentName="p">{`theme.colors.accent.{color}.`}</inlineCode>{` in `}<inlineCode parentName="p">{`react-ui-kit`}</inlineCode>{`.`}</p>
    <designTokenPreviews.AccentColors />
    <h2 {...{
      "id": "raw"
    }}>{`Raw`}</h2>
    <pre>{JSON.stringify(designTokens, null, 2)}</pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      